import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import Map from "./Map";

import SimpleLink from "../../components/SimpleLink";
import SectionTitle from "../../components/SectionTitle";

function Place() {
  return (
    <section id="contact" className="w-auto bg-zinc-100 py-10 sm:py-20 px-4">
      <div className="max-w-[960px] mx-auto grid grid-cols-1 sm:grid-cols-2">
        <div className="order-last sm:order-first flex items-center justify-center h-full min-h-[300px] w-full bg-zinc-500 hover:cursor-pointer">
          <Map />
        </div>
        <div className="py-20 px-4">
          <SectionTitle label="찾아 오시는 길" />

          <div className="flex flex-col justify-center items-center gap-y-3 text-center">
            <p className="text-base flex items-center"></p>
            <SimpleLink
              hasDarkBG={false}
              href="https://map.naver.com/v5/entry/place/1459384968?c=17,0,0,0,dh"
            >
              제주 제주시 관덕로 41 건물뒷편 1층 섬마을과자점{" "}
              <ArrowTopRightOnSquareIcon className="w-4 h-4 inline" />
            </SimpleLink>
            <p className="ml-5 inria text-base font-light flex items-center">
              TEL. 0507-1332-1636
            </p>
            <p className="inria text-base font-light flex items-center">
              OPEN - 10:00
              <br />
              CLOSE - 18:00
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Place;
