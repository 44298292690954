import { ReactNode, useEffect, useRef, useState } from "react";
import { classNames } from "../helpers";

interface SlideUpWrapperProps {
  children: ReactNode;
}

export default function SlideUpWrapper(props: SlideUpWrapperProps) {
  const [isVisible, setVisible] = useState(false);

  const ref = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      // In your case there's only one element to observe:
      if (entries[0].isIntersecting) {
        // Not possible to set it back to false like this:
        setVisible(true);
        // No need to keep observing:
        observer.unobserve(ref.current!);
      }
    });
    observer.observe(ref.current!);
    return () => observer.disconnect();
  }, []);

  return (
    <div
      ref={ref}
      className={classNames(
        isVisible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-10",
        "duration-700 transition-all"
      )}
    >
      {props.children}
    </div>
  );
}
