import React from "react";

import "./App.css";
import NavBar from "./components/NavBar";
import ParallaxRow from "./components/ParallaxRow";
import Brand from "./sections/brand/Brand";
import Daquoise from "./sections/daquoise/Daquoise";
import Footer from "./sections/footer/Footer";
import Header from "./sections/header/Header";
import Place from "./sections/place/Place";
import Products from "./sections/products/Products";

function App() {
  return (
    <div id="app-wrapper">
      <NavBar />
      <Header />
      <Brand />
      <ParallaxRow
        bgRule="bg-[url('./assets/daquoise/background-web.jpg')] bg-center"
        height="h-[70vh]"
      />
      {/* <ParallaxRow
        height="h-[90vh] md:h-[60vh]"
        bgRule="bg-[url('./assets/row_parallax_1.jpg')] bg-center"
      /> */}
      <Daquoise />
      <Products />
      {/* <ParallaxRow
        height="h-[60vh]"
        bgRule="bg-[url('./assets/row_parallax_2.jpg')] bg-bottom sepia saturate-50"
      /> */}
      <Place />
      <Footer />
    </div>
  );
}

export default App;
