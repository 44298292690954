interface DaquoiseTileProps {
  img: string;
  label: string;
}

function DaquoiseTile(props: DaquoiseTileProps) {
  return (
    <div>
      <img
        src={props.img}
        width="100%"
        className="drop-shadow-[5px_5px_2px_rgba(60,60,65,.2)]"
      />
      <p className="text-center">{props.label}</p>
    </div>
  );
}

export default DaquoiseTile;
