import { ReactNode } from "react";
import { classNames } from "../helpers";

interface SimpleLinkProps {
  href: string;
  hasDarkBG: boolean;
  label?: string;
  className?: string;
  children?: ReactNode;
}

SimpleLink.defaultProps = {
  hasDarkBG: true,
};

export default function SimpleLink(props: SimpleLinkProps) {
  return (
    <a
      className={classNames(
        "underline underline-offset-4 hover:no-underline",
        props.className ?? "",
        props.hasDarkBG ? "hover:text-blue-100" : "hover:text-blue-400"
      )}
      href={props.href}
      target="_blank"
      rel="noreferrer"
    >
      {props.label} {props.children}
    </a>
  );
}
