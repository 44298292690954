import { ReactNode } from "react";
import { classNames } from "../helpers";

interface ParallaxRowProps {
  height: string;
  bgRule: string;
  children?: ReactNode;
}

function ParallaxRow(props: ParallaxRowProps) {
  return (
    <section
      className={classNames(
        props.height ?? "h-[50vh]",
        "w-full bg-fixed bg-cover max-h-[50vh] sm:max-h-screen",
        "flex items-center justify-center bg-ios-fix",
        props.bgRule
      )}
      aria-hidden
    >
      {props.children}
    </section>
  );
}

export default ParallaxRow;
