import { useRef, useState, useEffect } from "react";
import SectionTitle from "../../components/SectionTitle";
import { classNames } from "../../helpers";

function Brand() {
  const ref = useRef<HTMLInputElement>(null);

  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        // In your case there's only one element to observe:
        if (entries[0].isIntersecting) {
          // Not possible to set it back to false like this:
          setVisible(true);
          // No need to keep observing:
          observer.unobserve(ref.current!);
        }
      },
      { root: null, rootMargin: "-100px" }
    );
    observer.observe(ref.current!);
    return () => observer.disconnect();
  }, []);

  return (
    <main
      id="bakery"
      className="w-full h-auto min-h-screen py-6 sm:py-0 px-4 bg-beigedark flex items-center overflow-hidden"
    >
      <div
        className={classNames(
          "max-w-[960px] mx-auto px-0 sm:px-10 grid grid-cols-1 sm:grid-cols-2 justify-center items-center"
        )}
      >
        <div
          className={classNames(
            isVisible
              ? "opacity-1 translate-x-0 rotate-0"
              : "opacity-0 -translate-x-0 sm:-translate-x-20 -rotate-[10deg]",
            "duration-[750ms] transition-all delay-150",
            "mt-10 mb-5 sm:mb-10 bg-zinc-100 py-16 px-8"
          )}
        >
          <SectionTitle label="LA BOUTIQUE" />
          <p ref={ref} className="text-xl">
            제주의 대표적인 문화유산 성산일출봉을 본떠 지역 특색의 제철 식자재를
            사용하여 만든 다쿠아즈 및 다양한 제주의 제철 식자재를 사용하여
            제과를 만들어 판매하고 있습니다.
          </p>
        </div>
        <div
          className={classNames(
            isVisible
              ? "opacity-1 translate-y-0 rotate-0"
              : "opacity-0 -translate-y-0 sm:translate-y-[100px] rotate-[5deg]",
            "duration-[1000ms] transition-all delay-0",
            "max-h-[90%] h-[300px] sm:h-[400px] md:h-[500px] lg:h-[600px]",
            "bg-[url('./assets/brand_1.jpg')] bg-center bg-cover",
            "shadow-[inset_0px_0px_4px_rgba(60,60,65,.7)]"
          )}
        ></div>
      </div>
    </main>
  );
}

export default Brand;
