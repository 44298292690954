import "leaflet/dist/leaflet.css";
import { MapContainer, Marker, TileLayer } from "react-leaflet";

import Leaflet from "leaflet";
import { classNames } from "../../helpers";
import { useState } from "react";

export default function Map() {
  const coords = { lat: 33.513547, lng: 126.523889 };
  const [toggled, toggle] = useState(false);

  console.log(window.matchMedia("(pointer: coarse)").matches);
  return (
    <div
      className="relative w-full h-full"
      onMouseLeave={() => {
        console.log("mouse leave");
        if (toggled) toggle(false);
      }}
    >
      <div
        key={Math.random()}
        onClick={() => {
          toggle(!toggled);
        }}
        onDrag={() => {
          toggle(!toggled);
        }}
        onTouchMove={() => {
          toggle(!toggled);
        }}
        className={classNames(
          toggled || window.matchMedia("(pointer: coarse)").matches
            ? "z-0"
            : "z-[9000]",
          "absolute  top-0 left-0 w-full h-full flex items-center justify-center bg-white bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity duration-300 ease-in-out"
        )}
      >
        {/* <CursorArrowRaysIcon className="w-36 inline text-zinc-700" /> */}
        <p className="inria text-2xl text-center font-bold drop-shadow-[0px_0px_2px_rgba(255,255,255,.8)]">
          확대/축소하거나 이동하려면 클릭하세요.
        </p>
      </div>
      <MapContainer
        className="w-full h-full"
        center={coords}
        zoom={13}
        scrollWheelZoom={!toggled}
        touchZoom={!toggled}
        dragging={!toggled}
      >
        <TileLayer
          attribution={
            '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          }
          // custom tile style are disponible :
          // cf https://leaflet-extras.github.io/leaflet-providers/preview/
          // url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png"
          url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker
          position={coords}
          icon={Leaflet.divIcon({
            html: "<div class='pin'></div><div class='pulse'></div>",
          })}
        >
          {/* <Popup>
            A pretty CSS3 popup. <br /> Easily customizable.
          </Popup> */}
        </Marker>
      </MapContainer>
    </div>
  );
}
