import { classNames } from "../helpers";

interface BigBorderProps {
  className?: string;
}

function BigBorder(props: BigBorderProps) {
  return (
    <div
      className={classNames(
        props.className ?? "",
        "border-t-8 border-t-beigedark w-[170px]"
      )}
    ></div>
  );
}

export default BigBorder;
