import ProductTile from "./ProductTile";

import { ArrowRightIcon } from "@heroicons/react/24/outline";
import SlideUpWrapper from "../../components/SlideUpWrapper";
import SectionTitle from "../../components/SectionTitle";

const images = [
  {
    bgUrl: "bg-[url('./assets/cakes/1_web.jpg')]",
    label: "Blabla du label",
    description: "Description rapide de blabla abla sdadsa",
  },
  { bgUrl: "bg-[url('./assets/cakes/2_web.jpg')]", label: "Blasdbla du label" },
  {
    bgUrl: "bg-[url('./assets/cakes/3_web.jpg')]",
    label: "Blla du lel",
    description: "Description rapide de blabla abla sdadsa",
  },
  // { bgUrl: "bg-[url('./assets/cakes/4.jpg')]", label: "Blabla du labe l" },
  {
    bgUrl: "bg-[url('./assets/cakes/5_web.jpg')]",
    label: "Blab a bexzc l",
    description: "Description rapide de blabla abla sdadsa",
  },
  {
    bgUrl: "bg-[url('./assets/cakes/6_web.jpg')]",
    label: "Blabxxla du lxabel",
  },
  {
    bgUrl: "bg-[url('./assets/cakes/7_web.jpg')]",
    label: "Blabxzla dxzu labzxel",
  },
  { bgUrl: "bg-[url('./assets/cakes/8_web.jpg')]", label: "Blabel" },
  { bgUrl: "bg-[url('./assets/cakes/9_web.jpg')]", label: "Blabz du lael" },
];

function Products() {
  return (
    <section id="products" className="py-32 bg-zinc-200">
      <div className="w-[80vw] max-w-[980px] mx-auto">
        <SectionTitle label="GOURMANDISES" />

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-20 justify-center">
          {images.map((element, index) => (
            <ProductTile {...element} key={"product-tile-" + index} />
          ))}

          <SlideUpWrapper>
            <a
              href="#"
              target="_blank"
              key={"product-tile-custom"}
              className="block col-span-1 w-full max-w-[60vw] mx-auto aspect-square bg-zinc-100 hover:shadow-[inset_0px_0px_5px_rgba(60,60,65,.5)] shadow-[inset_0px_0px_10px_rgba(60,60,65,.3)] transition-all duration-700 ease-in-out"
            >
              <div className="flex flex-col items-center justify-around h-full">
                <div className="w-full">
                  <img
                    src="./sns/instagram_color.svg"
                    width="40%"
                    alt="Logo of instagram"
                    lang="en"
                    className="my-6 mx-auto footer-icon"
                  />
                  <p className="w-full flex items-center justify-center leading-none">
                    더보기 <ArrowRightIcon className="h-6 inline" />
                  </p>
                </div>
              </div>
            </a>
          </SlideUpWrapper>
        </div>
      </div>
    </section>
  );
}

export default Products;
