import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { Swiper as SwiperCore } from "swiper/types";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import React, { useRef } from "react";
import { useWindowResize } from "../../hooks/useWindowResize";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { ShoppingCartIcon } from "@heroicons/react/24/solid";
import DaquoiseTile from "./DaquoiseTile";
import SectionTitle from "../../components/SectionTitle";
import { classNames } from "../../helpers";

const items = [
  {
    img: "/daquoise/1.png",
    label: "일출 다쿠아즈 인절미",
  },
  {
    img: "/daquoise/2.png",
    label: "일출 다쿠아즈 땅콩",
  },
  {
    img: "/daquoise/3.png",
    label: "일출 다쿠아즈 한라봉",
  },
  {
    img: "/daquoise/4.png",
    label: "일출 다쿠아즈 몽블랑",
  },
  {
    img: "/daquoise/5.png",
    label: "일출 다쿠아즈 녹차",
  },
];

function Daquoise() {
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  const swiperRef = useRef<SwiperCore>();

  const windowSize = useWindowResize();

  return (
    <section id="dacquoise" className="py-32 bg-zinc-50">
      <div className="w-[80vw] max-w-[1280px] mx-auto flex items-center justify-center">
        <div className="flex flex-col items-center">
          <SectionTitle label="SIGNATURE, LA DACQUOISE" />

          <div className="relative flex items-center">
            <ChevronLeftIcon
              ref={navigationPrevRef}
              className="h-14 w-14 text-slate-400 hover:text-slate-800 hover:cursor-pointer"
              onClick={() => swiperRef.current?.slidePrev()}
            />

            <Swiper
              modules={[Navigation, Pagination]}
              spaceBetween={windowSize.width <= 640 ? 0 : 10}
              slidesPerView={
                windowSize.width <= 640 ? 1 : windowSize.width <= 960 ? 2 : 3
              }
              navigation={false}
              loop={true}
              pagination={{
                dynamicBullets: true,
              }}
              onBeforeInit={(swiper) => {
                swiperRef.current = swiper;
              }}
              // className="w-"
              className="w-[60vw] sm:w-[70vw] md:w-[80vw] max-w-[960px]"
            >
              {items.map((item, index) => (
                <SwiperSlide
                  key={"slide-" + Math.floor(Math.random() * 999) + { index }}
                >
                  <DaquoiseTile img={item.img} label={item.label} />
                </SwiperSlide>
              ))}
            </Swiper>

            <ChevronRightIcon
              ref={navigationNextRef}
              className="h-14 w-14 text-slate-400 hover:text-slate-800 hover:cursor-pointer"
              onClick={() => swiperRef.current?.slideNext()}
            />
          </div>

          <div className="mt-32 grid grid-cols-1 sm:grid-cols-2">
            <div className="col-span-1 py-10">
              <h4 className="intra text-3xl font-[900] uppercase text-zinc-900">
                Unique & délicieuse
              </h4>
              <p className="inria mt-8 text-base text-left">
                독특하고 기발한 디저트인 다쿠아즈를 소개합니다! 프랑스인에게서
                영감을 받은 이 천상의 창조물은 감각에 진정한 즐거움을
                선사합니다.
                <br />
                <br />
                가볍고 바람이 잘 통하는 머랭과 버터크림 층으로 제작된 당사의
                다쿠아즈는 매력적이고 잊을 수 없는 장난스러운 구름 모양이
                특징입니다.
                <br />
                <br />
                우리의 다쿠아즈는 친밀한 모임부터 대규모 축하 행사에 이르기까지
                모든 행사에 적합합니다. 독특하고 기억에 남을 만한 모양은
                손님들에게 깊은 인상을 남길 것이며, 섬세한 질감과 맛있는 맛은
                손님들이 계속해서 다시 찾게 만들 것입니다.
              </p>
            </div>
            <div className="col-span-1 py-10 flex items-center justify-center">
              <div
                className={classNames(
                  "chevron-bg relative mx-auto w-[50%] aspect-square rounded-full overflow-hidden bg-white",
                  "shadow-[inset_0px_0px_6px_2px_rgba(0,0,0,.1)]",
                  "flex items-center justify-center"
                )}
              >
                {/* <img className="transition-all" src="./daquoise/2_4k.png" /> */}
                <a
                  href="https://smartstore.naver.com/is_bake_shop/products/6309250717"
                  target="_blank"
                  rel="noreferrer"
                  className={classNames(
                    "transition-all rounded-full shadow-[0px_0px_6px_2px_rgba(0,0,0,.1)] duration-[550ms] aspect-square",
                    "group w-[40%] flex items-center justify-center text-center",
                    "bg-beigedark text-white animate-floating hover:animate-none",
                    "hover:w-[70%] hover:shadow-[0px_0px_5px_50px_rgba(0,0,0,.1)]"
                  )}
                >
                  <span className="text-2xl">
                    <ShoppingCartIcon className="transition-all h-[50%] w-[50%] aspect-square inline" />
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Daquoise;
