import { classNames } from "../../helpers";
import SVGLogo from "../../assets/logo.svg";

function Header() {
  return (
    <header id="app-header" className="w-auto h-screen overflow-hidden">
      <div className="grid grid-cols-3 h-full pt-[100px] noise-bg">
        <div className="animate-slideRightToLeftBlur col-span-3 sm:col-span-2 flex items-center justify-center">
          <p className="transition-all text-justify font-[900] text-4xl sm:text-[5vw] leading-tight text-zinc-100 px-8 md:px-[15%] drop-shadow-[2px_2px_1px_rgba(0,0,0,1)]">
            <span className="text-beige">오직 섬마을</span> 과자점에서만 만나볼
            수 있는 시그니처 제과 성산일출봉{" "}
            <a
              href="#dacquoise"
              className="bg-beigedark text-white px-2 hover:text-black"
            >
              다쿠아즈!
            </a>
          </p>
        </div>

        <div
          className={classNames(
            "animate-slideRightToLeftBlur",
            "hidden sm:flex items-center justify-center col-span-3 sm:col-span-1"
          )}
        >
          <img
            className="block aspect-square w-[35%] sm:w-[75%]"
            src={SVGLogo}
            alt="Logo of the company, three shape of different cake."
          />
        </div>
      </div>
      {/* <div className="flex items-center h-full w-[95vw] sm:w-[80vw] md:w-[65vw] mx-auto">
        
      </div> */}
    </header>
  );
}

export default Header;
