import { useEffect, useRef, useState } from "react";
import { classNames } from "../helpers";
import BigBorder from "./BigBorder";

interface SectionTitleProps {
  className?: string;
  label: string;
}

function SectionTitle(props: SectionTitleProps) {
  const ref = useRef<HTMLInputElement>(null);

  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        // In your case there's only one element to observe:
        if (entries[0].isIntersecting) {
          // Not possible to set it back to false like this:
          setVisible(true);
          // No need to keep observing:
          observer.unobserve(ref.current!);
        }
      },
      { root: null, rootMargin: "-100px" }
    );
    observer.observe(ref.current!);
    return () => observer.disconnect();
  }, []);

  return (
    <>
      <BigBorder className="mx-auto" />
      <h3
        ref={ref}
        className={classNames(
          isVisible
            ? "tracking-normal translate-y-0 opacity-100"
            : "tracking-[-1rem] -translate-y-5 opacity-0",
          "transition-all duration-[550ms] inria mt-7 text-center text-6xl font-bold mb-14"
        )}
      >
        {props.label}
      </h3>
    </>
  );
}

export default SectionTitle;
